import { TextField } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { SavedKeyword } from 'common/types/common';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

interface Props {
  kw: string;
  kws: Partial<SavedKeyword>[];
  onChange: (kw: string) => void;
  disabled: boolean;
  sx?: SystemStyleObject;
}

export function KWInput({ kw, onChange, disabled, sx, kws }: Props): ReactElement {
  const [unique, setUnique] = useState<boolean>(true);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  useEffect(() => {
    setUnique(Boolean(kws.filter(globalkW => globalkW.keyword === kw).length <= 1));
  }, [kw, kws]);

  return (
    <TextField
      value={kw}
      sx={sx}
      disabled={disabled}
      onChange={handleChange}
      error={!unique}
      helperText={unique ? '' : 'Keyword should be unique'}
      fullWidth
    />
  );
}
