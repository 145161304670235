import { AxiosResponse } from 'axios';
import { ApiService } from 'common/services';
import omit from 'lodash/omit';
import { RetailerType } from 'common/constants/entities';
import {
  QueueStats,
  AIModel,
  AIQueueProduct,
  AttributeHistoryEntry,
  SavedKeyword,
  QueueStatsV4,
  QueueV4Product,
} from 'common/types/common';
import {
  AcceptAIValidationBody,
  ControlAIQueue,
  DeleteConditionalInstructionsData,
  DeletePDPKeywordsBody,
  DeleteProductAttributesBody,
  EnhanceSpecialInstructionData,
  EnhanceTemplateParams,
  FetchProductCountParams,
  GenerateAIBody,
  GenerateAIForCategoriesBody,
  GenerateAIForCategoryBody,
  GenerateAIForScopeBody,
  GenerateAIForWPIDAllAttributesBody,
  GenerateAIForWPIDSingleAttributeBody,
  GenerateAiResponse,
  GenerateAiSyncData,
  GenerateSwatchImageBody,
  GenerateSwatchImageResponse,
  GenereateAIForTCINBody,
  GetAICategoryProductsParams,
  GetAIModelsParams,
  GetAIQueueProductsV4Params,
  GetAIQueueStatsV4Params,
  GetAttributeAITemplatesParams,
  GetAttributeAITemplatesResponse,
  GetAttributesGroupsResponse,
  GetConditionalInstructionsFieldsResponse,
  GetConditionalInstructionsResponse,
  GetSyncHistoryFilters,
  PatchAttributeFlagsBody,
  PatchPDPKeywordsBody,
  PostAttributesGroupsBody,
  PostPDPKeywordsBody,
  PreviewAIPromptParams,
  PreviewAIPromptResponse,
  ResetAtributeRulesBody,
  SaveAttributeRulesBody,
  SavePromptTemplateBody,
  SavePromptTemplateBodyV2,
  ScrapeFromURLResponse,
  SelectDefaultTemplateBody,
  SendEnhanceNotificationBody,
  UpdateConditionalInstructionsBody,
  ValidateAIBody,
} from './attributes-api.types';

class AttributesApiService extends ApiService {
  getMappings = (): Promise<AxiosResponse<{ mapping: Record<string, string[]> }>> => {
    return this.get('/attributes/mapping');
  };

  postMappings = (attributes: Record<string, string[]>) => {
    return this.post('/attributes/mapping', { attributes, strategy: 'replace' });
  };

  patchAttributeFlags = (settings: PatchAttributeFlagsBody) => {
    return this.patch('/attributes', { ...settings });
  };

  patchRetailerAttributeFlags = (
    retailer: string,
    settings: PatchAttributeFlagsBody,
    category_id?: string | number
  ) => {
    return this.patch(`/vendors/${retailer}/attributes`, { ...settings, ...(category_id ? { category_id } : {}) });
  };

  getSuggestions = (
    categoryID: number | string,
    attribute: string
  ): Promise<AxiosResponse<{ suggestions: Record<string, string[]> }>> => {
    return this.get('/attributes/suggestions', { params: { category_id: categoryID, attributes: attribute } });
  };

  savePromptTemplate = (body: SavePromptTemplateBody) => {
    return this.put(`/attributes/generation/${body.retailer.toLowerCase()}/templates`, omit(body, 'retailer'));
  };

  savePromptTemplateV2 = (body: SavePromptTemplateBodyV2, retailer: string) => {
    return this.post(`/attributes/generation/${retailer}/templates/save`, body);
  };

  generateAI = (body: GenerateAIBody): Promise<AxiosResponse<GenerateAiResponse>> => {
    return this.post('/attributes/generation/generate', body);
  };

  enhanceTemplate = (params: EnhanceTemplateParams, retailer: string) => {
    return this.get(`/attributes/generation/${retailer}/templates/enhance`, {
      params,
    });
  };

  previewAI = (
    retailer: RetailerType,
    params: PreviewAIPromptParams
  ): Promise<AxiosResponse<PreviewAIPromptResponse>> => {
    return this.get(`/attributes/generation/${retailer.toLowerCase()}/preview`, {
      params: omit(params, 'retailer'),
    });
  };

  genereateAIForTCIN = (body: GenereateAIForTCINBody, retailer: string): Promise<AxiosResponse<GenerateAiResponse>> => {
    return this.post(`/attributes/generation/${retailer}/v2/generate/sync`, body);
  };

  generateAIForWPIDSingleAttribute = (
    body: GenerateAIForWPIDSingleAttributeBody,
    retailer: string
  ): Promise<AxiosResponse<GenerateAiResponse>> => {
    return this.post(`/attributes/generation/${retailer}/v2/generate/sync`, body);
  };

  generateAIForWPIDAllAttributes = (
    body: GenerateAIForWPIDAllAttributesBody
  ): Promise<AxiosResponse<GenerateAiResponse>> => {
    return this.post(`/attributes/generation/generate/sync/wpid/${body.wpid}/all`, omit(body, 'wpid'));
  };

  generateAIForCategory = (
    body: GenerateAIForCategoryBody,
    retailer: string
  ): Promise<AxiosResponse<GenerateAiResponse>> => {
    return this.post(`/attributes/generation/${retailer}/v2/generate/batch`, body);
  };

  generateAIForCategories = (
    body: GenerateAIForCategoriesBody,
    retailer: string
  ): Promise<AxiosResponse<GenerateAiResponse>> => {
    return this.post(`/attributes/generation/${retailer}/v2/generate/batch`, body);
  };

  genereateAIForScope = (body: GenerateAIForScopeBody) => {
    return this.post('/attributes/generation/generate/batch/scope', body);
  };

  // Target

  generateTargetAIForCategory = (
    body: GenerateAIForCategoryBody,
    retailer: string
  ): Promise<AxiosResponse<GenerateAiResponse>> => {
    return this.post(`/attributes/generation/${retailer}/v2/generate/batch`, omit(body, 'categoryID'));
  };

  generateTargetAIForCategories = (
    body: GenerateAIForCategoriesBody,
    retailer: string
  ): Promise<AxiosResponse<GenerateAiResponse>> => {
    return this.post(`/attributes/generation/${retailer}/v2/generate/batch`, body);
  };

  genereateTargetAIForScope = (body: GenerateAIForScopeBody, retailer: string) => {
    return this.post(`/attributes/generation/${retailer}/v2/generate/batch`, body);
  };

  // Target

  getAttributesGroups = (
    categoryID: number | string,
    retailer: RetailerType
  ): Promise<AxiosResponse<GetAttributesGroupsResponse>> => {
    return this.get(`/attributes/generation/${retailer.toLowerCase()}/groups/${categoryID}`);
  };

  postAttributesGroups = (body: PostAttributesGroupsBody, retailer: string) => {
    return this.post(
      `/attributes/generation/${retailer}/groups/${body.categoryID}`,
      omit(body, 'retailer', 'categoryID')
    );
  };

  // Target

  getAIQueueStats = (retailer: RetailerType): Promise<AxiosResponse<{ stats: QueueStats }>> => {
    return this.get(`/attributes/generation/${retailer.toLowerCase()}/queue/stats`);
  };

  getAIQueueStatsV4 = (
    retailer: RetailerType,
    params: GetAIQueueStatsV4Params
  ): Promise<AxiosResponse<{ batches: QueueStatsV4[] }>> => {
    return this.get(`/attributes/generation/${retailer.toLowerCase()}/queue/v4/batches`, {
      params,
    });
  };

  getAIQueueProductsV4 = (
    retailer: RetailerType,
    params: GetAIQueueProductsV4Params
  ): Promise<AxiosResponse<{ products: QueueV4Product[] }>> => {
    return this.get(`/attributes/generation/${retailer.toLowerCase()}/queue/v4/products`, {
      params,
    });
  };

  getAIQueueStatus = (): Promise<AxiosResponse<{ status: 'active' | 'pause' }>> => {
    return this.get('/attributes/generation/queue/status');
  };

  cancelAIBatch = (batchId: number): Promise<AxiosResponse<{ commandID: string }>> => {
    return this.post('/attributes/generation/queue/cancel', { batchId });
  };

  controlAIQueue = (control: ControlAIQueue) => {
    return this.post(`/attributes/generation/queue/${control}`);
  };

  getAICategoryProducts = (
    params: GetAICategoryProductsParams
  ): Promise<AxiosResponse<{ products: Array<AIQueueProduct> }>> => {
    return this.get(`/attributes/generation/${params.retailer}/queue/products`, { params: omit(params, 'retailer') });
  };

  getAIModels = (params?: GetAIModelsParams): Promise<AxiosResponse<{ models: Array<AIModel> }>> => {
    return this.get('/attributes/generation/ai-models', { params });
  };

  saveAttributeRules = (body: SaveAttributeRulesBody, retailer: string) => {
    return this.patch(`/vendors/${retailer}/attributes`, body);
  };

  resetAttributeRules = (body: ResetAtributeRulesBody, retailer: string) => {
    return this.post(`/vendors/${retailer}/attributes/restore`, body);
  };

  validateAI = (retailer: string, body: ValidateAIBody) => {
    return this.post(`/attributes/generation/${retailer}/validate`, body);
  };

  acceptAIValidation = (retailer: string, body: AcceptAIValidationBody) => {
    return this.post(`/attributes/generation/${retailer}/accept-ai-validation`, body);
  };

  getAttributeAITemplates = (
    retailer: RetailerType,
    params: GetAttributeAITemplatesParams
  ): Promise<AxiosResponse<GetAttributeAITemplatesResponse>> => {
    return this.get(`/attributes/generation/${retailer.toLowerCase()}/templates`, {
      params: { attributeId: params.attributeId },
    });
  };

  selectDefaultTemplate = (body: SelectDefaultTemplateBody, retailer: string) => {
    return this.post(`/attributes/generation/${retailer}/templates/select`, body);
  };

  getConditionalInstructions = (retailer: RetailerType): Promise<AxiosResponse<GetConditionalInstructionsResponse>> => {
    return this.get(`/attributes/generation/${retailer.toLowerCase()}/conditional-instructions`);
  };

  getConditionalInstructionsFields = (
    retailer: RetailerType
  ): Promise<AxiosResponse<GetConditionalInstructionsFieldsResponse>> => {
    return this.get(`/attributes/generation/${retailer.toLowerCase()}/conditional-instructions/fields`);
  };

  postConditionalInstructions = (data: UpdateConditionalInstructionsBody, retailer: string) => {
    return this.post(`/attributes/generation/${retailer}/conditional-instructions`, data);
  };

  patchConditionalInstructions = (data: UpdateConditionalInstructionsBody, retailer: string) => {
    return this.patch(
      `/attributes/generation/${retailer}/conditional-instructions/${data.id}`,
      omit(data, ['retailer', 'id'])
    );
  };

  deleteConditionalInstructions = (retailer: string, data: DeleteConditionalInstructionsData) => {
    return this.delete(`/attributes/generation/${retailer}/conditional-instructions/${data.id}`);
  };

  generateWalmartAiSync = (data: GenerateAiSyncData, retailer: string) => {
    return this.post(`/attributes/generation/${retailer}/v2/generate/sync`, data);
  };

  generateAiSync = (retailer: string, data: GenerateAiSyncData) => {
    return this.post(`/attributes/generation/${retailer}/v2/generate/sync`, data);
  };

  enhanceSpecialInstruction = (
    retailer: RetailerType,
    data: EnhanceSpecialInstructionData
  ): Promise<AxiosResponse<{ specialInstruction: string }>> => {
    return this.post(`/attributes/generation/${retailer.toLowerCase()}/ai-enhancement/special-instruction`, data);
  };

  getSyncHistory = (
    filters: GetSyncHistoryFilters,
    retailer: RetailerType
  ): Promise<AxiosResponse<AttributeHistoryEntry[]>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/attributes/sync-history`, { params: filters });
  };

  generateSwatchImage = (body: GenerateSwatchImageBody): Promise<AxiosResponse<GenerateSwatchImageResponse>> => {
    return this.post('/attributes/generation/swatch/generate', body);
  };

  scrapeFromURL = (url: string): Promise<AxiosResponse<ScrapeFromURLResponse>> => {
    return this.post('/attributes/scrape', { url });
  };

  deleteProductAttributes = (retailer: string, tcin: string, body: DeleteProductAttributesBody) => {
    return this.post(`/vendors/${retailer}/products/${tcin}/attributes/delete`, body);
  };

  addProductImages = (retailer: string, tcin: string, images: Array<File>) => {
    const formData = new FormData();
    images.forEach(p => {
      formData.append('files', p);
    });

    return this.post(`/vendors/${retailer}/products/${tcin}/images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  deleteProductImages = (retailer: string, tcin: string, images: Array<string>) => {
    return this.post(`/vendors/${retailer}/products/${tcin}/images/delete`, { images });
  };

  deleteWalmartProductImages = (wpid: string, images: Array<string>) => {
    return this.post(`/content/${wpid}/images/delete`, { images });
  };

  fetchExternalAttributes = (retailer: string, tcin: string) => {
    return this.post(`/vendors/${retailer}/attributes/fetch-external`, { tcin });
  };

  getKeywords = (retailer: string, tcin: string): Promise<AxiosResponse<{ keywords: SavedKeyword[] }>> => {
    return this.get(`/attributes/generation/${retailer.toLowerCase()}/templates/pdp-optimization/seo-keywords`, {
      params: { tcin },
    });
  };

  postPDPKeywords = (retailer: string, body: PostPDPKeywordsBody) => {
    return this.post(`/attributes/generation/${retailer.toLowerCase()}/templates/pdp-optimization/seo-keywords`, body);
  };

  patchPDPKeywords = (retailer: string, body: PatchPDPKeywordsBody) => {
    return this.patch(`/attributes/generation/${retailer.toLowerCase()}/templates/pdp-optimization/seo-keywords`, body);
  };

  deletePDPKeywords = (retailer: string, body: DeletePDPKeywordsBody) => {
    return this.delete(
      `/attributes/generation/${retailer.toLowerCase()}/templates/pdp-optimization/seo-keywords`,
      body
    );
  };

  sendEnhanceNotification = (retailer: string, body: SendEnhanceNotificationBody) => {
    return this.post(`/attributes/generation/${retailer}/ai-enhancement/notify`, body);
  };

  fetchKeywords = (retailer: string, tcin: string) => {
    return this.post(`/attributes/generation/${retailer.toLowerCase()}/templates/pdp-optimization/seo-keywords/fetch`, {
      tcin,
    });
  };

  fetchProductCount = (
    retailer: string,
    params: FetchProductCountParams
  ): Promise<AxiosResponse<{ count: number }>> => {
    return this.get(`/attributes/generation/${retailer.toLowerCase()}/product-count`, { params });
  };
}

export const attributesApi = new AttributesApiService(process.env.REACT_APP_AI_API);
