import { ChangeEvent, ReactElement, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { usePdpTypesQuery } from 'common/hooks/api/queries/use-pdp-query';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { Styles } from 'common/types/styles';
import { userService } from 'common/services/user.service';
import { RetailerType, SyndigoRetailers } from 'common/constants/entities';
import { ProductAITabValue } from './constants';
import { PdpTemplateType } from './pdp-template-type';
import { useGeneratePDP } from './use-generate-pdp';

const styles: Styles = {
  container: { overflowY: 'auto', maxHeight: '440px', p: 2, boxSizing: 'border-box', mb: 2 },
  btn: { flexGrow: 1 },
  checkboxContainer: { mb: 2, ml: 1 },
  checkboxes: { display: 'flex', flexDirection: 'column', ml: 1, mb: 2 },
};

interface Props {
  selectedTab: ProductAITabValue;
  pid: string;
}

export function PdpTemplateContent({ selectedTab, pid }: Props): ReactElement {
  const defaultPdpSeoOptimization = !userService.ensureRetailer(RetailerType.Autozone);

  const [pdpAttributesWithIssuesOnly, setPdpAttributesWithIssuesOnly] = useState<boolean>(false);
  const [pdpGeneration, setPdpGeneration] = useState<boolean>(false);
  const [pdpSeoOptimization, setPdpSeoOptimization] = useState<boolean>(defaultPdpSeoOptimization);

  const { data: types } = usePdpTypesQuery();

  const { handleGenerateAI, isLoading } = useGeneratePDP({
    issuesOnly: pdpAttributesWithIssuesOnly,
    pdpGen: pdpGeneration,
    seoGen: pdpSeoOptimization,
    pid,
  });

  const isSEOOptimizationAvailable =
    userService.ensureRetailer(RetailerType.Target) ||
    userService.ensureRetailer(RetailerType.Instacart) ||
    userService.ensureRetailers(SyndigoRetailers) ||
    userService.ensureRetailer(RetailerType.Autozone) ||
    userService.ensureRetailer(RetailerType.Amazon);

  const handlePdpGenerationChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPdpGeneration(checked);
  };

  const handlePdpSeoOptimization = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPdpSeoOptimization(checked);
  };

  const handleChangeAttributesWithIssuesOnly = (_: ChangeEvent<HTMLInputElement>, value: boolean) => {
    setPdpAttributesWithIssuesOnly(value);
  };

  return (
    <Box>
      <Box sx={styles.container}>
        {types?.map((type, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <PdpTemplateType key={i} selectedTab={selectedTab} pid={pid} type={type} />
        ))}
      </Box>
      {userService.ensureRetailer(RetailerType.Walmart) && (
        <FormControlLabel
          sx={styles.checkboxContainer}
          control={<Checkbox checked={pdpAttributesWithIssuesOnly} onChange={handleChangeAttributesWithIssuesOnly} />}
          label={<Typography>Only PDP with Issues</Typography>}
        />
      )}
      {isSEOOptimizationAvailable && (
        <Box sx={styles.checkboxes}>
          {userService.ensureRetailers([RetailerType.Target, RetailerType.Amazon]) && (
            <FormControlLabel
              control={<Checkbox checked={pdpGeneration} onChange={handlePdpGenerationChange} />}
              // eslint-disable-next-line react/no-unescaped-entities
              label={<Typography sx={styles.label}>PDP Generation</Typography>}
            />
          )}

          <FormControlLabel
            control={<Checkbox checked={pdpSeoOptimization} onChange={handlePdpSeoOptimization} />}
            // eslint-disable-next-line react/no-unescaped-entities
            label={<Typography sx={styles.label}>PDP SEO Optimization</Typography>}
          />
        </Box>
      )}
      <Box component="span" sx={styles.btn}>
        <ButtonWithLoading variant="contained" color="primary" loading={isLoading} onClick={handleGenerateAI} fullWidth>
          Generate
        </ButtonWithLoading>
      </Box>
    </Box>
  );
}
