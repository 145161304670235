import { Box, Button, Popover, Tab, Tabs, TextField } from '@mui/material';
import { Styles } from 'common/types/styles';
import { FieldLabelWrapper } from 'common/ui/inputs/field-label-wrapper';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { ChangeEvent, ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import {
  useGenerateAIForTCIN,
  useSendEnhanceNotificationMutation,
} from 'common/hooks/api/mutations/use-attributes-mutation';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { useQueryClient } from '@tanstack/react-query';
import { ContentQueryKey, useContentQuery } from 'common/hooks/api/queries/use-content-query';
import { userService } from 'common/services/user.service';
import { PocRetailers, RetailerType } from 'common/constants/entities';
import {
  GenerateAIForWPIDSingleAttributeBody,
  GenereateAIForTCINBody,
} from 'common/services/api/attributes/attributes-api.types';
import { getPDPValues } from 'common/services/attributes.service';

type Notification = {
  prevAttribute: string;
  newAttribute: string;
  status: 'empty' | 'pending' | 'ready';
  comment: string;
};

const pdpAttributes = ['product_title', 'product_short_description', 'product_long_description'];

const initPDPObj = () => {
  return pdpAttributes.reduce((acc, item) => {
    acc[item] = '';
    return acc;
  }, {} as Record<string, string>);
};

const initNotifyObj = () => {
  return pdpAttributes.reduce((acc, item) => {
    acc[item] = {
      prevAttribute: '',
      newAttribute: '',
      comment: '',
      status: 'empty',
    };
    return acc;
  }, {} as Record<string, Notification>);
};

enum TabValue {
  ProductTitle = 0,
  ProductShortDescription,
  ProductLongDescription,
}

const TabFieldNameObj = {
  [TabValue.ProductTitle]: 'product_title',
  [TabValue.ProductShortDescription]: 'product_short_description',
  [TabValue.ProductLongDescription]: 'product_long_description',
};

const styles: Styles = {
  container: { width: 500, p: 2, boxSizing: 'border-box', display: 'flex', flexDirection: 'column', gap: 2 },
  btns: { display: 'flex', gap: 2 },
  btn: { flexGrow: 1 },
};

interface Props {
  anchorEl: HTMLElement;
  onClose: () => void;
  pid: string;
}

export function TargetPDPEnhancePopup({ anchorEl, onClose, pid }: Props): ReactElement {
  const queryClient = useQueryClient();

  const { mutate: mutateGenerateRetailerAI, isLoading: isRetailerGenerateAILoading } = useGenerateAIForTCIN();
  const { mutate: sendNotification } = useSendEnhanceNotificationMutation();

  // const [enhancedAttributes, setEnhancedAttributes] = useState<Record<string, string>>(initPDPObj());
  const [selectedTab, setSelectedTab] = useState<TabValue>(TabValue.ProductTitle);
  const [comments, setComments] = useState<Record<string, string>>(initPDPObj());
  const [notification, setNotification] = useState<Record<string, Notification>>(initNotifyObj());

  const { data: contentData } = useContentQuery({ pid, enabled: true });

  const isGenerateAILoading = isRetailerGenerateAILoading;

  const handleTabChange = (_: SyntheticEvent<Element, Event>, value: TabValue) => {
    setSelectedTab(value);
  };

  const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComments(comments => ({ ...comments, [e.target.name]: e.target.value }));
  };

  const remapNotification = (
    notification: Record<string, Notification>,
    pdp: ReturnType<typeof getPDPValues>,
    attributeSource: 'prevAttribute' | 'newAttribute',
    status: 'empty' | 'pending' | 'ready'
  ) => {
    const copiedNotification = { ...notification };

    pdpAttributes.forEach(key => {
      copiedNotification[key].comment = comments[key] ?? '-';
      copiedNotification[key].status = status;

      switch (key) {
        case 'product_title': {
          copiedNotification[key][attributeSource] = pdp.ai.title ?? '-';
          break;
        }
        case 'product_short_description': {
          copiedNotification[key][attributeSource] = pdp.ai.desc ?? '-';
          break;
        }
        case 'product_long_description': {
          copiedNotification[key][attributeSource] = pdp.ai.feats ?? '-';
          break;
        }
        default:
          break;
      }
    });

    return copiedNotification;
  };

  const handleEnhance = () => {
    const body = {
      generateAttributes: false,
      optimizeAttributes: true,
      pdpAttributesWithIssuesOnly: false,
    };

    const pocBody = {
      optimizeAttributes: true,
      overwriteOptimizedAttributes: true,
    };

    const commonAttributeBody: Partial<GenerateAIForWPIDSingleAttributeBody & GenereateAIForTCINBody> = {
      ...(userService.ensureRetailer(RetailerType.Walmart) || userService.ensureRetailer(RetailerType.Flywheel)
        ? { wpid: pid }
        : { tcin: pid }),
      ...(!userService.ensureRetailers(PocRetailers) ? body : pocBody),
      comments,
    };

    const pdp = getPDPValues(contentData);
    setNotification(remapNotification(notification, pdp, 'prevAttribute', 'pending'));

    const handleAttributeSuccess = () => {
      queryClient.invalidateQueries([ContentQueryKey.Content, pid]);
    };

    mutateGenerateRetailerAI(commonAttributeBody as GenereateAIForTCINBody, { onSuccess: handleAttributeSuccess });
  };

  const handlePropagateComment = () => {
    setComments(comments =>
      Object.keys(comments).reduce((acc, item) => {
        acc[item] = comments[TabFieldNameObj[selectedTab]];
        return acc;
      }, {} as Record<string, string>)
    );
  };

  useEffect(() => {
    if (anchorEl) return;

    setComments(initPDPObj());
    // setEnhancedAttributes(initPDPObj());
  }, [anchorEl]);

  useEffect(() => {
    if (!contentData || notification.product_title?.status !== 'pending') return;

    const pdp = getPDPValues(contentData);
    setNotification(remapNotification(notification, pdp, 'newAttribute', 'ready'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentData]);

  useEffect(() => {
    if (notification.product_title?.status !== 'ready') return;

    const notificationEntries = Object.entries(notification);

    sendNotification({
      attributeName: pdpAttributes.join(';'),
      comment: notificationEntries.map(([key, value]) => `${key}: ${value.comment}`).join(';'),
      currentAttributeValue: notificationEntries.map(([key, value]) => `${key}: ${value.prevAttribute}`).join(';'),
      enhancedAttributeValue: notificationEntries.map(([key, value]) => `${key}: ${value.newAttribute}`).join(';'),
    });

    setNotification(initNotifyObj());
  }, [notification, sendNotification]);

  return (
    <Popover anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <Box sx={styles.container}>
        <Tabs onChange={handleTabChange} value={selectedTab} centered>
          <Tab value={TabValue.ProductTitle} tabIndex={TabValue.ProductTitle} label="Title" />
          <Tab
            value={TabValue.ProductLongDescription}
            tabIndex={TabValue.ProductLongDescription}
            label="Long Description"
          />
          <Tab
            value={TabValue.ProductShortDescription}
            tabIndex={TabValue.ProductShortDescription}
            label="Short Description"
          />
        </Tabs>
        <FieldLabelWrapper label="Comment">
          <TextField
            minRows={3}
            maxRows={3}
            onChange={handleCommentChange}
            value={comments[TabFieldNameObj[selectedTab]]}
            name={TabFieldNameObj[selectedTab]}
            multiline
            fullWidth
          />
        </FieldLabelWrapper>
        {/* <FieldLabelWrapper label="Enhanced Attribute">
          {!enhancedAttributes[TabFieldNameObj[selectedTab]] && !isGenerateAILoading && '-'}
          {isGenerateAILoading && <Skeleton variant="text" />}
          {!isGenerateAILoading && enhancedAttributes[TabFieldNameObj[selectedTab]] && (
            <Typography fontSize={14}>{enhancedAttributes[TabFieldNameObj[selectedTab]]}</Typography>
          )}
        </FieldLabelWrapper> */}
        <Box sx={styles.btns}>
          <Button sx={styles.btn} variant="outlined" onClick={handlePropagateComment}>
            Propagate Comment
          </Button>
          <ButtonWithLoading variant="contained" sx={styles.btn} onClick={handleEnhance} loading={isGenerateAILoading}>
            <AutoAwesomeIcon />
            Enhance Attributes
          </ButtonWithLoading>
        </Box>
      </Box>
    </Popover>
  );
}
