import { Box, Button, Skeleton, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import sanitize from 'sanitize-html';
import { ImagesSlider } from 'common/ui/containers/images-slider';
import { userService } from 'common/services/user.service';
import { RetailerType } from 'common/constants/entities';
import { AttributeEnhanceButton, AttributeEnhancePopup } from 'common/ui/shared/attribute-enhance-popup';
import { IssuesButton, IssuesPopup } from 'common/ui/shared/issues-popup';
import { getCategoryIDKeyFromProduct } from 'modules/common/content/utils/mappings';
import { TextComparator } from 'common/ui/containers/text-comparator';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SaveIcon from '@mui/icons-material/Save';
import { DetailsTabValue, SEOCompareSource, OptimizationStatus } from 'modules/common/product-details-page/constants';
import { ProductSaveBody } from 'common/services/api/content/content-api.types';
import {
  useProductSaveMutation,
  useRetailerProductSaveMutation,
} from 'common/hooks/api/mutations/use-content-mutation';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';
import { useGeneratePDP } from 'common/ui/shared/pdp-ai-modal/use-generate-pdp';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { getPDPValues } from 'common/services/attributes.service';
import { useKeywordsQuery } from 'common/hooks/api/queries/use-attributes-query';
import { useSelectedContentContext } from 'common/ui/shared/selected-content-ctx';
import { ContentQueryKey } from 'common/hooks/api/queries/use-content-query';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelectedProductQuery } from '../../hooks/queries';
import { CompareToggles, EditModeToggles, ProductToggles, SEOCompareSourceToggles } from '../btns';
import { SeoComparator } from '../../../../../common/ui/shared/seo-comparator';
import { KeywordsTable } from '../keywords-table';
import { getBTNsStyles } from '../btns/styles';

const styles: Styles = {
  container: { display: 'flex', width: '100%', gap: 4 },
  images: { maxWidth: 400, width: '100%' },
  contents: { display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 720, width: '100%' },
  title: { fontSize: 22 },
  desc: { fontSize: 13, whiteSpace: 'pre-wrap' },
  toggleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5',
    justifyContent: 'flex-end',
    width: '100%',
  },
  toggle: { fontSize: 12 },
  input: { maxWidth: 500, width: '100%' },
  saveBTN: { alignSelf: 'flex-start', maxWidth: 200, width: '100%', ml: 'auto' },
  optimizeBTN: { alignSelf: 'flex-start', maxWidth: 200, width: '100%' },
  optimizeContainer: { display: 'flex', flexDirection: 'column', gap: 1 },
  headings: { fontSize: '1.25rem', fontWeight: 600 },
  toggles: { display: 'flex', flexDirection: 'column', gap: 1 },
  optimizationBox: { display: 'flex', alignItems: 'center', gap: 1, alignSelf: 'flex-end' },
};

interface Props {
  source: DetailsTabValue;
}

export function ProductHead({ source }: Props): ReactElement {
  const queryClient = useQueryClient();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [optimizationStatus, setOptimizationStatus] = useState<OptimizationStatus>(OptimizationStatus.Regular);
  const [targetIssueAttributes, setTargetIssueAttributes] = useState<Array<string>>(null);
  const [issuesAnchor, setIssuesAnchor] = useState<HTMLButtonElement>(null);
  const [enhanceAnchor, setEnhanceAnchor] = useState<HTMLButtonElement>(null);
  const [enhanceAttributeKey, setEnhanceAttributeKey] = useState<string>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [seoCompareMode, setSEOCompareMode] = useState<boolean>(false);
  const [seoCompareSource, setSeoCompareSource] = useState<SEOCompareSource>(SEOCompareSource.SEO);
  const [optimizedTitle, setOptimizedTitle] = useState<string>('');
  const [optimizedDesc, setOptimizedDesc] = useState<string>('');
  const [optimizedFeatures, setOptimizedFeatures] = useState<string>('');
  const [dirty, setDirty] = useState<boolean>(false);
  const [highlightedKW, setHighlightedKW] = useState<string>('');

  const { selectedContent } = useSelectedContentContext();

  const { data: productData, isLoading: isProductLoading } = useSelectedProductQuery();
  const { data: keywordsData } = useKeywordsQuery(selectedContent);
  // const { mutate: mutateGenerateAi, isLoading: isAIGenerationLoading } = useGenerateAiSyncMutation();
  const { mutate: mutateWalmartSave } = useProductSaveMutation(selectedContent);
  const { mutate: mutateTargetSave } = useRetailerProductSaveMutation(selectedContent);

  const mutateSave = userService.ensureRetailer(RetailerType.Walmart) ? mutateWalmartSave : mutateTargetSave;

  const { handleGenerateAI, isLoading: isGenerateAILoading } = useGeneratePDP({
    issuesOnly: false,
    pdpGen: optimizationStatus === OptimizationStatus.Regular,
    seoGen: optimizationStatus === OptimizationStatus.SEO,
    pid: selectedContent,
  });

  const images = useMemo(() => {
    return Object.keys(productData?.content?.images || {})?.reverse();
  }, [productData?.content?.images]);

  const pdp = getPDPValues(productData);
  const { title } = pdp.initial;
  const description = pdp.initial.desc;
  const features = pdp.initial.feats;
  const keywords = keywordsData?.keywords?.map(kw => kw.keyword) ?? [];

  const getOptimizedTitle = useCallback(
    (overrideStatus?: OptimizationStatus) => {
      return (overrideStatus ?? optimizationStatus) === OptimizationStatus.Regular ? pdp.ai.title : pdp.seo.title;
    },
    [optimizationStatus, pdp.ai.title, pdp.seo.title]
  );

  const getOptimizedDesc = useCallback(
    (overrideStatus?: OptimizationStatus) => {
      return (overrideStatus ?? optimizationStatus) === OptimizationStatus.Regular ? pdp.ai.desc : pdp.seo.desc;
    },
    [optimizationStatus, pdp.ai.desc, pdp.seo.desc]
  );

  const getOptimizedFeatures = useCallback(
    (overrideStatus?: OptimizationStatus) => {
      return (overrideStatus ?? optimizationStatus) === OptimizationStatus.Regular ? pdp.ai.feats : pdp.seo.feats;
    },
    [optimizationStatus, pdp.ai.feats, pdp.seo.feats]
  );

  const enhanceDisabled = useMemo(() => {
    return Boolean(!getOptimizedTitle() && !getOptimizedDesc() && !getOptimizedFeatures());
  }, [getOptimizedDesc, getOptimizedFeatures, getOptimizedTitle]);

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOptimizedTitle(e.target.value);
    setDirty(true);
  };

  const handleDescChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOptimizedDesc(e.target.value);
    setDirty(true);
  };

  const handleFeaturesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOptimizedFeatures(e.target.value);
    setDirty(true);
  };

  const getTitleNameValue = () => {
    switch (source) {
      case DetailsTabValue.Final:
      case DetailsTabValue.Compare:
        return Array.isArray(optimizedTitle) ? optimizedTitle.join('\n') : optimizedTitle;
      case DetailsTabValue.Original:
        return title ?? '';
      default:
        return '-';
    }
  };

  const getDescValue = () => {
    switch (source) {
      case DetailsTabValue.Final:
      case DetailsTabValue.Compare:
        return Array.isArray(optimizedDesc) ? optimizedDesc.join('\n') : optimizedDesc;
      case DetailsTabValue.Original:
        return description ?? '';
      default:
        return '-';
    }
  };

  const getFeaturesValue = () => {
    switch (source) {
      case DetailsTabValue.Final:
      case DetailsTabValue.Compare:
        return Array.isArray(optimizedFeatures) ? optimizedFeatures.join('\n') : optimizedFeatures;
      case DetailsTabValue.Original:
        return features ?? '';
      default:
        return '-';
    }
  };

  const getDescriptionTitle = () => {
    switch (userService.getRetailer()) {
      case RetailerType.Walmart:
      case RetailerType.Flywheel:
        return 'Short Description';
      case RetailerType.Instacart:
      case RetailerType.SyndigoWalmart:
      case RetailerType.SyndigoAmazon:
      case RetailerType.SyndigoKroger:
      case RetailerType.SyndigoTarget:
      case RetailerType.Autozone:
      case RetailerType.Target:
      case RetailerType.Amazon:
      case RetailerType.Costco:
        return 'Description';
      default:
        return null;
    }
  };

  const getFeaturesTitle = () => {
    switch (userService.getRetailer()) {
      case RetailerType.Walmart:
      case RetailerType.Flywheel:
        return 'Long Description';
      case RetailerType.Instacart:
      case RetailerType.SyndigoWalmart:
      case RetailerType.SyndigoAmazon:
      case RetailerType.SyndigoKroger:
      case RetailerType.SyndigoTarget:
      case RetailerType.Autozone:
      case RetailerType.Target:
      case RetailerType.Amazon:
      case RetailerType.Costco:
        return 'Features';
      default:
        return null;
    }
  };

  const handleChangeOptimizedStatus = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setOptimizationStatus(OptimizationStatus.SEO);
    } else {
      setOptimizationStatus(OptimizationStatus.Regular);
    }
  };

  const handleSEOCompareSourceChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSeoCompareSource(SEOCompareSource.SEO);
    } else {
      setSeoCompareSource(SEOCompareSource.Original);
    }
  };

  const handleEditModeChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setEditMode(checked);
  };

  const handleSEOCompareModeChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSEOCompareMode(checked);
  };

  const handleIssuesOpen = (attributeKey: Array<string>, anchor: HTMLButtonElement) => {
    setTargetIssueAttributes(attributeKey);
    setIssuesAnchor(anchor);
  };

  const handleIssuesClose = () => {
    setTargetIssueAttributes(null);
    setIssuesAnchor(null);
  };

  const handleEnhanceOpen = (attributeKey: string, anchor: HTMLButtonElement) => {
    setEnhanceAttributeKey(attributeKey);
    setEnhanceAnchor(anchor);
  };

  const handleEnhanceClose = () => {
    setEnhanceAttributeKey(null);
    setEnhanceAnchor(null);
  };

  const getHeadingBraces = () => {
    switch (source) {
      case DetailsTabValue.Original:
        return '(Original)';
      case DetailsTabValue.Final:
        return '(Optimized)';
      default:
        return '';
    }
  };

  const handleSavePDP = () => {
    const previousTitle = getOptimizedTitle();
    const previousDesc = getOptimizedDesc();
    const previousFeatures = getOptimizedFeatures();

    const data: ProductSaveBody = { attributes: {} };

    if (previousTitle !== optimizedTitle) {
      data.attributes[
        optimizationStatus === OptimizationStatus.Regular ? 'optimized_product_title' : 'seo_optimized_product_title'
      ] = { value: optimizedTitle };
    }

    if (previousDesc !== optimizedDesc) {
      data.attributes[
        optimizationStatus === OptimizationStatus.Regular
          ? 'optimized_product_short_description'
          : 'seo_optimized_product_short_description'
      ] = { value: optimizedDesc };
    }

    if (previousFeatures !== optimizedFeatures) {
      data.attributes[
        optimizationStatus === OptimizationStatus.Regular
          ? 'optimized_product_long_description'
          : 'seo_optimized_product_long_description'
      ] = { value: optimizedFeatures };
    }

    if (!Object.keys(data.attributes).length) {
      setDirty(false);
      return;
    }

    mutateSave(data, {
      onSuccess: () => {
        toast.success('PDP saved succesfully');
        setDirty(false);
        queryClient.invalidateQueries([ContentQueryKey.Content, selectedContent]);
      },
    });
  };

  // const handleGenerateTitle = () => {
  //   mutateGenerateAi({
  //     optimizeAttributes: ['title'],
  //     ...(userService.getAPIRetailer() === RetailerType.Walmart
  //       ? { wpid: productData?.pid }
  //       : { tcin: productData?.tcin }),
  //   });
  // };

  // const handleGenerateDesc = () => {
  //   mutateGenerateAi({
  //     optimizeAttributes: ['description'],
  //     ...(userService.getAPIRetailer() === RetailerType.Walmart
  //       ? { wpid: productData?.pid }
  //       : { tcin: productData?.tcin }),
  //   });
  // };

  // const handleGenerateFeatures = () => {
  //   mutateGenerateAi({
  //     optimizeAttributes: ['features'],
  //     ...(userService.getAPIRetailer() === RetailerType.Walmart
  //       ? { wpid: productData?.pid }
  //       : { tcin: productData?.tcin }),
  //   });
  // };

  useEffect(() => {
    setOptimizedTitle(getOptimizedTitle());
  }, [getOptimizedTitle]);

  useEffect(() => {
    setOptimizedDesc(getOptimizedDesc());
  }, [getOptimizedDesc]);

  useEffect(() => {
    setOptimizedFeatures(getOptimizedFeatures());
  }, [getOptimizedFeatures]);

  useEffect(() => {
    if (
      !pdp.seo.title ||
      !pdp.seo.desc ||
      !pdp.seo.feats ||
      !userService.ensureRetailers([RetailerType.Target, RetailerType.Amazon])
    )
      return;
    setOptimizationStatus(OptimizationStatus.SEO);
  }, [pdp]);

  useEffect(() => {
    if (source === DetailsTabValue.Final) return;
    setEditMode(false);
  }, [source]);

  return (
    <>
      <Box sx={styles.toggles}>
        {userService.ensureRetailers([RetailerType.Target, RetailerType.Amazon]) &&
          [DetailsTabValue.Final, DetailsTabValue.Compare].includes(source) && (
            <>
              <Box sx={styles.optimizationBox}>
                {pdp.ai.title && pdp.ai.desc && pdp.ai.feats ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <RadioButtonUncheckedIcon color="success" />
                )}
                PDP Optimized
              </Box>
              <Box sx={styles.optimizationBox}>
                {pdp.seo.title && pdp.seo.desc && pdp.seo.feats ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <RadioButtonUncheckedIcon color="success" />
                )}
                SEO Optimized
              </Box>
            </>
          )}

        {source === DetailsTabValue.Final && <EditModeToggles value={editMode} handleChange={handleEditModeChange} />}
        {source === DetailsTabValue.Compare && (
          <>
            <CompareToggles value={seoCompareMode} handleChange={handleSEOCompareModeChange} />
            {seoCompareMode && (
              <SEOCompareSourceToggles value={seoCompareSource} handleChange={handleSEOCompareSourceChange} />
            )}
          </>
        )}
        {(source === DetailsTabValue.Final || (source === DetailsTabValue.Compare && !seoCompareMode)) &&
          !userService.ensureRetailers([
            RetailerType.Amazon,
            RetailerType.Target,
            RetailerType.Walmart,
            RetailerType.Flywheel,
          ]) && (
            <ProductToggles
              optimizationStatus={optimizationStatus}
              handleChangeOptimizedStatus={handleChangeOptimizedStatus}
            />
          )}
      </Box>

      {editMode && (
        <Box sx={getBTNsStyles().wrapper}>
          <Button variant="contained" sx={styles.saveBTN} disabled={!dirty} onClick={handleSavePDP}>
            <SaveIcon fontSize="small" />
            Save PDP
          </Button>
        </Box>
      )}

      <Box sx={styles.container}>
        <Box sx={styles.images}>
          {isProductLoading ? (
            <Skeleton width="100%" height={375} variant="rounded" />
          ) : (
            <ImagesSlider images={images} />
          )}
        </Box>

        <Box sx={styles.contents}>
          {enhanceDisabled && (
            <Box sx={styles.optimizeContainer}>
              <Typography>{optimizationStatus} optimization has not been run on this product yet.</Typography>
              <ButtonWithLoading
                variant="contained"
                sx={styles.optimizeBTN}
                onClick={handleGenerateAI}
                loading={isGenerateAILoading}
              >
                <AutoAwesomeIcon fontSize="small" />
                Run Optimization
              </ButtonWithLoading>
            </Box>
          )}
          {(!enhanceDisabled || ![DetailsTabValue.Final, DetailsTabValue.Compare].includes(source)) && (
            <>
              <div>
                <Typography sx={styles.headings}>
                  Product Title {getHeadingBraces()}
                  {(userService.ensureRetailer(RetailerType.Walmart) ||
                    userService.ensureRetailer(RetailerType.Flywheel)) && (
                    <>
                      <IssuesButton
                        product={productData}
                        attributeKeys={['product_title', 'product_name']}
                        buttonProps={{
                          onClick: e => {
                            handleIssuesOpen(['product_title', 'product_name'], e.currentTarget);
                          },
                        }}
                      />
                      <AttributeEnhanceButton
                        size="small"
                        onClick={e => handleEnhanceOpen('product_title', e.currentTarget)}
                      />
                    </>
                  )}
                  {/* {source === DetailsTabValue.Original && (
                    <GenerateButton
                      title="Generate Title"
                      isLoading={isAIGenerationLoading}
                      onGenerate={handleGenerateTitle}
                    />
                  )} */}
                </Typography>
                {isProductLoading ? (
                  <Skeleton variant="text" height={27} />
                ) : (
                  <div>
                    {source === DetailsTabValue.Compare && !seoCompareMode && (
                      <TextComparator
                        previousText={title ?? ''}
                        currentText={getTitleNameValue() ?? ''}
                        sx={styles.title}
                      />
                    )}

                    {source === DetailsTabValue.Compare && seoCompareMode && (
                      <SeoComparator
                        keywords={keywords}
                        value={
                          seoCompareSource === SEOCompareSource.SEO
                            ? getOptimizedTitle(OptimizationStatus.SEO) ?? ''
                            : title ?? ''
                        }
                        highlightedKW={highlightedKW}
                        sx={styles.title}
                      />
                    )}

                    {[DetailsTabValue.Final, DetailsTabValue.Original].includes(source) && !editMode && (
                      <Typography sx={styles.title}>{getTitleNameValue()}</Typography>
                    )}

                    {source === DetailsTabValue.Final && editMode && (
                      <TextField
                        variant="standard"
                        value={optimizedTitle}
                        onChange={handleTitleChange}
                        sx={styles.input}
                      />
                    )}
                  </div>
                )}
              </div>
              <div>
                <Typography sx={styles.headings}>
                  {getDescriptionTitle()} {getHeadingBraces()}
                  {(userService.ensureRetailer(RetailerType.Walmart) ||
                    userService.ensureRetailer(RetailerType.Flywheel)) && (
                    <>
                      <IssuesButton
                        product={productData}
                        attributeKeys={['product_short_description', 'short_description']}
                        buttonProps={{
                          onClick: e => {
                            handleIssuesOpen(['product_short_description', 'short_description'], e.currentTarget);
                          },
                        }}
                      />
                      <AttributeEnhanceButton
                        size="small"
                        onClick={e => handleEnhanceOpen('product_short_description', e.currentTarget)}
                      />
                    </>
                  )}
                  {/* {source === DetailsTabValue.Original && !isProductLoading && (
                <GenerateButton
                  title="Generate Description"
                  isLoading={isAIGenerationLoading}
                  onGenerate={handleGenerateDesc}
                />
              )} */}
                </Typography>
                {isProductLoading ? (
                  <Skeleton variant="text" height={12} />
                ) : (
                  <div>
                    {source === DetailsTabValue.Compare && !seoCompareMode && (
                      <TextComparator
                        previousText={description?.length ? sanitize(description || '') : ''}
                        currentText={getDescValue()?.length ? sanitize(getDescValue() || '') : ''}
                        sx={styles.desc}
                      />
                    )}

                    {source === DetailsTabValue.Compare && seoCompareMode && (
                      <SeoComparator
                        keywords={keywords}
                        value={
                          seoCompareSource === SEOCompareSource.SEO
                            ? getOptimizedDesc(OptimizationStatus.SEO) ?? ''
                            : description ?? ''
                        }
                        highlightedKW={highlightedKW}
                        sx={styles.desc}
                      />
                    )}

                    {[DetailsTabValue.Final, DetailsTabValue.Original].includes(source) && !editMode && (
                      <Typography
                        sx={styles.desc}
                        dangerouslySetInnerHTML={{
                          __html: getDescValue()?.length ? sanitize(getDescValue() || '') : '-',
                        }}
                      />
                    )}

                    {source === DetailsTabValue.Final && editMode && (
                      <TextField
                        variant="standard"
                        minRows={6}
                        maxRows={6}
                        value={optimizedDesc}
                        onChange={handleDescChange}
                        sx={styles.input}
                        multiline
                      />
                    )}
                  </div>
                )}
              </div>
              <div>
                <Typography sx={styles.headings}>
                  {getFeaturesTitle()} {getHeadingBraces()}
                  {(userService.ensureRetailer(RetailerType.Walmart) ||
                    userService.ensureRetailer(RetailerType.Flywheel)) && (
                    <>
                      <IssuesButton
                        product={productData}
                        attributeKeys={['product_long_description', 'product_description']}
                        buttonProps={{
                          onClick: e => {
                            handleIssuesOpen(['product_long_description', 'product_description'], e.currentTarget);
                          },
                        }}
                      />
                      <AttributeEnhanceButton
                        size="small"
                        onClick={e => handleEnhanceOpen('product_long_description', e.currentTarget)}
                      />
                    </>
                  )}
                  {/* {source === DetailsTabValue.Original && !isProductLoading && (
                <GenerateButton
                  title="Generate Features"
                  isLoading={isAIGenerationLoading}
                  onGenerate={handleGenerateFeatures}
                />
              )} */}
                </Typography>
                {isProductLoading ? (
                  <Skeleton variant="text" height={12} />
                ) : (
                  <div>
                    {source === DetailsTabValue.Compare && !seoCompareMode && (
                      <TextComparator
                        previousText={features?.length ? sanitize(features || '') : ''}
                        currentText={getFeaturesValue()?.length ? sanitize(getFeaturesValue() || '') : ''}
                        sx={styles.desc}
                      />
                    )}

                    {source === DetailsTabValue.Compare && seoCompareMode && (
                      <SeoComparator
                        keywords={keywords}
                        value={
                          seoCompareSource === SEOCompareSource.SEO
                            ? getOptimizedFeatures(OptimizationStatus.SEO) ?? ''
                            : features ?? ''
                        }
                        highlightedKW={highlightedKW}
                        sx={styles.desc}
                      />
                    )}

                    {[DetailsTabValue.Final, DetailsTabValue.Original].includes(source) && !editMode && (
                      <Typography
                        sx={styles.desc}
                        dangerouslySetInnerHTML={{
                          __html: getFeaturesValue()?.length ? sanitize(getFeaturesValue() || '') : '-',
                        }}
                      />
                    )}

                    {source === DetailsTabValue.Final && editMode && (
                      <TextField
                        variant="standard"
                        minRows={6}
                        maxRows={6}
                        value={optimizedFeatures}
                        onChange={handleFeaturesChange}
                        sx={styles.input}
                        multiline
                      />
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </Box>

        <IssuesPopup
          product={productData}
          attributeKeys={targetIssueAttributes?.filter(Boolean) ?? []}
          anchorElement={issuesAnchor}
          onClose={handleIssuesClose}
          extendedColumns={['Status', 'Details']}
          extendedSize
        />

        <AttributeEnhancePopup
          pid={selectedContent}
          categoryID={(productData ?? {})[getCategoryIDKeyFromProduct()]}
          functions={['attribute']}
          anchorEl={enhanceAnchor}
          attributeKey={enhanceAttributeKey}
          onClose={handleEnhanceClose}
          pdp
        />

        {seoCompareMode && source === DetailsTabValue.Compare && (
          <KeywordsTable
            beforeSEOTitle={title ?? ''}
            afterSEOTitle={getOptimizedTitle(OptimizationStatus.SEO) ?? ''}
            beforeSEODesc={description ?? ''}
            afterSEODesc={getOptimizedDesc(OptimizationStatus.SEO) ?? ''}
            beforeSEOFeats={features ?? ''}
            afterSEOFeats={getOptimizedFeatures(OptimizationStatus.SEO) ?? ''}
            keywords={keywordsData?.keywords ?? []}
            highlightedKW={highlightedKW}
            setHightlightedKW={setHighlightedKW}
          />
        )}
      </Box>
    </>
  );
}
